import * as React from "react"
import Layout from '../components/layout/Layout'

const NotFoundPage = () => (
  <Layout>
    <h1>404: Sivua ei löytynytkään</h1>
    <p>Harmi, tätä sivua ei ole olemassa, yritäpä uudelleen...</p>
  </Layout>
)

export default NotFoundPage